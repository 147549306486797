.dashboard-container {
    height: 100%;
}

.dashboard-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.detail-row {
    display: flex;
    justify-content: space-between;
}

.detail {
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
}

.detail h4 {
    padding-right: 10px;
}

.content-section {
    flex-grow: 100;
    overflow: auto;
}

.highlights-box {
    border: 1px solid #568eb6;
}