.dashboard-container {
    height: 100%;
    overflow: hidden;
}

.dashboard-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.detail-row {
    display: flex;
    justify-content: space-between;
}

.detail {
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
}

.detail h4 {
    padding-right: 10px;
}

.content-section {
    flex-grow: 100;
    overflow: auto;
}

.table-header {
    background: #7A918D;
    color: white;
    font-weight: bold;
    border: none;
}

.table-row {
    background: #F2F2F2;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiDataGrid-cellContent {
    font-size: 0.8em !important;
    font-weight: bold !important;
}