.nearest-container {
    display: flex;
    flex-flow: row;
    height: 100%;
}

.nearest-side {
    flex: 1;
}

.left-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}