body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.container {
  height: 100vh;
  background-color: #f7fdff;
}

.title {
  font-size: 1.5em;
  font-weight: 500;
  color: #5e5e5e;
}

.link {
  text-decoration: none;
  color: #009ce2;
  font-size: 1em;
}

.input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.input-row label {
  width: 35%;
  color: #707b81;
}

.input-row .MuiFormControl-root {
  width: 65%;
}

.content-wrapper {
  padding-left: 5%;
  padding-right: 5%;
}

.right-aligned {
  justify-content: right;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.expanded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.modal-container {
  width: 40vw;
}

h5 {
  font-size: 1em;
}

table,
th,
td {
  border: 1px solid #037ab4;
  border-collapse: collapse;
  padding: 5px;
}

td {
  text-align: center;
}

.filled {
  background: #6c6c6c70;
}

.desktop {
  display: block;
}

.mobile {
  display: none !important;
}

@media only screen and (max-width: 875px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}