.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-img {
    width: 25%;
    height: auto;
}

.login-form {
    width: 33%;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.login-btn {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}