.new-appointment {
    padding: 3%;
}

.appointment-body {
    display: flex;
    flex-grow: 100;
    overflow-y: auto;
    margin: 3%;
}

.appointment-header {
    margin: 0;
    margin-bottom: 10px;
}

.appointment-rows {
    display: flex;
    flex-direction: column;
}

.appointment-row {
    background-color: #F2F2F2;
    padding: 10px 5px;
    margin: 2px 0;
    cursor: pointer;
}

.appointment-row-selected {
    background-color: #7A918D;
    color: #F2F2F2;
    padding: 10px 5px;
    margin: 2px 0;
    cursor: pointer;
}

.appointment-row:hover {
    background-color: #7A918D;
    color: #F2F2F2;
    cursor: pointer;
}

.light-font {
    font-size: 0.8em;
    padding: 0;
}

.bold-font {
    font-size: 1em;
    font-weight: bold;
    padding: 0;
}

.btn {
    background-color: #7A918D;
    color: #F2F2F2;
    width: 100%;
    padding: 0.8em;
    cursor: pointer;
    border: none;
}

.btn:hover {
    color: #7A918D;
    background-color: #F2F2F2;
}